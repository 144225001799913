import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';

const Case2 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/2.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});

      return (
        <div className={classes.case} id="simple-articles-provider">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>
              Use Case #2. Simple Articles provider
            </h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 60}}>
            This is a common way to send your information about products and
            prices to the server. See more specific cases below.
          </p>
          <Img fluid={img} style={{maxWidth: 689}} />
          <div className={classes.flex50} style={{marginTop: 60}}>
            <h5>JSON</h5>
            <h5>Java</h5>
          </div>
          <div className={classes.flex50}>
            <p>
              POST 
{' '}
<span className={classes.textHead}>or</span>
              {' PUT /taxes/{vat}'}
            </p>
            <p>list(), get(), sample</p>
          </div>
          <div className={classes.flex50}>
            <p>
              POST 
{' '}
<span className={classes.textHead}>or</span>
              {' PUT /categories /{identifier}'}
            </p>
            <p>list(), get(), sample</p>
          </div>
          <div className={classes.flex50}>
            <p>
              POST 
{' '}
<span className={classes.textHead}>or</span>
              {' PUT /products /{identifier}'}
            </p>
            <p>list(), get(), sample</p>
          </div>
          <div className={classes.flex50}>
            <p>
              POST 
{' '}
<span className={classes.textHead}>or</span>
              {' PUT /commodities /{identifier}'}
            </p>
            <p>list(), get(), sample</p>
          </div>
        </div>
      );
    }}
  />
);

Case2.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    flex50: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case2);
